import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  padding: 8px;
  flex: 1 1 320px;
  max-width: 400px;
  position: relative;

  // this is important to make the paper 100% height
  > div {
    height: 100%;
  }
`;

export const ContainerContent = styled.div`
  height: 200px;
`;

export const Intro = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Fullname = styled.div`
  font-size: 2rem;
`;

export const Position = styled.div`
  font-weight: 0.8rem;
  color: ${Colors.GreyLightest};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 4px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 900px;

  > button {
    margin: 0 0 0 32px;
  }
`;

export const ContactsList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  border: 1px dotted ${Colors.GreyLight};
`;

export const SkeletonWrapper = styled.div`
  display: flex;
`;

export const LocationUrl = styled(Link)`
  transform: translateY(-8px);
`;

export const Actions = styled.div`
  position: absolute;
  display: flex;
  gap: 0px;
  top: 16px;
  right: 8px;
  padding: 8px;
  cursor: pointer;
`;
