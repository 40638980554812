import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';

import { Colors } from '../../../../styles/colors';
import { FieldWrapper } from '../FieldWrapper';
import { useRef, useState } from 'react';
import { EraserIcon, SparklingIcon } from '../../Icons';
import { RichTextInputProps } from './RichTextInput.types';
import { TinyLoader } from '../../Loader';

export const RichTextInput = ({
  name,
  noMargin,
  defaultValue,
  label,
  handleImproveDescription,
  handleFixTypo,
  isAIPowered = false,
  ...rest
}: RichTextInputProps) => {
  const { setValue, control } = useFormContext();
  const [selection, setSelection] = useState<{
    index: number;
    length: number;
  } | null>(null);
  const [isAiLoading, setIsAiLoading] = useState<boolean>(false);
  const quillRef = useRef<any>(null);

  function undoChange() {
    if (quillRef.current) {
      // console.log(quillRef.current.editor.history.stack.undo);
      // @ts-ignore-next-line
      quillRef.current.editor.history.undo();
    }
  }

  function redoChange() {
    if (quillRef.current) {
      // @ts-ignore-next-line
      quillRef.current.editor.history.redo();
    }
  }

  async function onImproveDescription() {
    if (!handleImproveDescription) return;
    if (!quillRef.current) return;
    if (!selection) return;
    if (selection.length < 100) {
      toast.error('No selection. Please select at least 100 characters to get AI suggestions on your selection.');
      return;
    }
    if (selection.length > 1000) {
      toast.error('No selection. Please select no more than 1000 characters to get AI suggestions on your selection.');
      return;
    }

    try {
      const selectedContent = quillRef.current.editor.getContents(selection.index, selection.length);
      if (!selectedContent.ops[0].insert) {
        toast.error('Something went wrong. Please try again.');
        return;
      }

      setIsAiLoading(true);
      const result = await handleImproveDescription(selectedContent.ops[0].insert);
      const formattedResult = `${result}\n`;

      if (!result) {
        toast.error('Something went wrong. Please try again.');
      } else {
        quillRef.current.editor.deleteText(selection.index, selection.length, 'user');
        quillRef.current.editor.insertText(selection.index, formattedResult, 'user');
        quillRef.current.editor.setSelection(selection.index, result.length, 'user');
      }
    } catch (error) {
    } finally {
      setIsAiLoading(false);
    }
  }

  async function onFixTypo() {
    if (!handleFixTypo) return;
    if (!quillRef.current) return;
    if (!selection) return;
    if (selection.length < 6) {
      toast.error('No selection. Please select at least 5 characters to get AI spell check on your selection.');
      return;
    }

    try {
      const selectedContent = quillRef.current.editor.getContents(selection.index, selection.length);
      if (!selectedContent.ops[0].insert) {
        toast.error('Something went wrong. Please try again.');
        return;
      }

      setIsAiLoading(true);
      const result = await handleFixTypo(selectedContent.ops[0].insert);
      const formattedResult = `${result}`;

      if (!result) {
        toast.error('Something went wrong. Please try again.');
      } else {
        quillRef.current.editor.deleteText(selection.index, selection.length, 'user');
        quillRef.current.editor.insertText(selection.index, formattedResult, 'user');
        quillRef.current.editor.setSelection(selection.index, result.length, 'user');
      }
    } catch (error) {
    } finally {
      setIsAiLoading(false);
    }
  }

  const modules = {
    toolbar: isAIPowered ? `#toolbar` : undefined,
    history: {
      delay: 2000,
      maxStack: 1000,
      userOnly: false,
    },
  };

  const isAiImproveDescriptionEnabled = !!(selection?.length && selection?.length >= 100 && selection?.length <= 1000);
  const isAiFixTypoEnabled = !!(selection?.length && selection?.length >= 5);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin}>
          <WrapperQuill>
            <LabelQuill>{label}</LabelQuill>
            {isAIPowered && (
              <QuillToolbar
                undoChange={undoChange}
                redoChange={redoChange}
                isAiImproveDescriptionEnabled={isAiImproveDescriptionEnabled}
                isAiFixTypoEnabled={isAiFixTypoEnabled}
                isAiLoading={isAiLoading}
                onFixTypo={onFixTypo}
                onImproveDescription={onImproveDescription}
              />
            )}
            <ReactQuill
              // @ts-ignore-next-line
              ref={quillRef}
              modules={modules}
              theme="snow"
              value={field.value}
              // @ts-ignore-next-line
              onChange={(value) => setValue(name, value)}
              onChangeSelection={(range, source, editor) => {
                const selection = editor.getSelection();
                setSelection(selection);
              }}
              {...rest}
            />
          </WrapperQuill>
        </FieldWrapper>
      )}
    />
  );
};

export const RichTextInputReadOnly = ({ children }: { children: React.ReactNode }) => {
  return (
    <WrapperReadOnlyQuill>
      <ReactQuill value={children ? children.toString() : undefined} readOnly={true} theme={'bubble'} />
    </WrapperReadOnlyQuill>
  );
};

const LabelQuill = styled.span`
  margin-bottom: 8px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const WrapperQuill = styled.div`
  position: relative;
  .ql-toolbar {
    border: 1px solid red;
    display: flex;
    justify-content: flex-end;
  }
  .ql-container {
    border-radius: 0 0 5px 5px;
    border: 1px solid ${Colors.GreyLight};
  }
  .ql-editor {
    min-height: 160px;
    font-size: 1rem;
  }
`;

const WrapperReadOnlyQuill = styled.div`
  .ql-editor {
    font-size: 1rem;
    font-family: Raleway, sans-serif;
  }
`;

const QuillToolbar = ({
  undoChange,
  redoChange,
  isAiLoading,
  isAiImproveDescriptionEnabled,
  isAiFixTypoEnabled,
  onImproveDescription,
  onFixTypo,
}: {
  undoChange: () => void;
  redoChange: () => void;
  isAiLoading?: boolean;
  isAiImproveDescriptionEnabled?: boolean;
  isAiFixTypoEnabled?: boolean;
  onImproveDescription?: () => void;
  onFixTypo?: () => void;
}) => (
  <div id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button
        className="ql-undo"
        onClick={() => {
          undoChange();
        }}
      >
        <CustomUndo />
      </button>
      <button
        className="ql-redo"
        onClick={() => {
          redoChange();
        }}
      >
        <CustomRedo />
      </button>
    </span>
    {onImproveDescription && (
      <span className="ql-formats">
        {isAiLoading ? (
          <TinyLoader />
        ) : (
          <button
            className="ql-undo"
            onClick={() => {
              onImproveDescription();
            }}
          >
            {isAiImproveDescriptionEnabled ? (
              <SparklingIcon size={1.3} />
            ) : (
              <SparklingIcon size={1.3} color={Colors.BlackLightest} />
            )}
          </button>
        )}
      </span>
    )}
    {onFixTypo && (
      <span className="ql-formats">
        {isAiLoading ? (
          <TinyLoader />
        ) : (
          <button
            className="ql-undo"
            onClick={() => {
              onFixTypo();
            }}
          >
            {isAiFixTypoEnabled ? <EraserIcon size={1.4} /> : <EraserIcon size={1.4} color={Colors.BlackLightest} />}
          </button>
        )}
      </span>
    )}
  </div>
);

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
  </svg>
);
