import { ContactProps } from './Contact.types';
import { Colors } from '../../../styles/colors';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Paper } from '../../atoms/Paper';
import { Avatar } from '../../atoms/Avatar';
import { ExternalLinkIcon, PhoneIcon, MailIcon, LinkedinIcon, AddIcon, DeleteIcon, EditIcon } from '../../atoms/Icons';
import { PrimaryButton } from '../../atoms/Button';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { SkeletonBlock, SkeletonButton, SkeletonCircle } from '../../atoms/Skeleton';
import {
  Actions,
  Container,
  ContainerContent,
  Fullname,
  Intro,
  List,
  ListItem,
  LocationUrl,
  Position,
  SkeletonWrapper,
  WrapperButtons,
} from './Contact.styles';
import { IconButton } from '../../atoms/Icons/Icon';

export const Contact: React.FC<any> = ({
  fullName,
  email = '',
  linkedin = '',
  position = '',
  phoneNumber = '',
  avatar = '',
  isSkeleton = false,
  onAddClick,
  onDeleteClick,
  onEditClick,
}: React.PropsWithChildren<ContactProps>) => {
  const contact = (
    <ContainerContent>
      <Actions>
        {onDeleteClick && (
          <IconButton>
            <DeleteIcon size={1.2} onClick={onDeleteClick} />
          </IconButton>
        )}
        {onEditClick && (
          <IconButton>
            <EditIcon size={1.2} onClick={onEditClick} />
          </IconButton>
        )}
      </Actions>
      <Intro>
        <Avatar image={`${avatar}`} size="large" />
        <div>
          <Fullname>{fullName}</Fullname>
          <Position>{position}</Position>
        </div>
      </Intro>
      <List>
        {Boolean(phoneNumber) && (
          <ListItem>
            <MailIcon size={1.5} />
            {email}
          </ListItem>
        )}
        {Boolean(phoneNumber) && (
          <ListItem>
            <PhoneIcon size={1.5} />
            {phoneNumber}
          </ListItem>
        )}

        {Boolean(linkedin) ? (
          <ListItem>
            <LinkedinIcon size={1.5} />
            Linkedin Profile
            <LocationUrl target="_blank" to={linkedin}>
              <ExternalLinkIcon size={1} />
            </LocationUrl>
          </ListItem>
        ) : (
          <ListItem>
            <Spacer y={16} />
          </ListItem>
        )}
      </List>
    </ContainerContent>
  );
  const skeletonList = (
    <>
      <SkeletonWrapper>
        <SkeletonCircle radius={60} style={{ marginBottom: '16px' }}></SkeletonCircle>
        <Spacer x={16} />
        <SkeletonButton style={{ height: '60px', marginBottom: '16px' }}></SkeletonButton>
      </SkeletonWrapper>
      <SkeletonBlock style={{ height: '92px', marginBottom: '32px' }}></SkeletonBlock>
    </>
  );

  return (
    <ErrorBoundary message="Molecules\Contact">
      <Container>
        <Paper elevation={1}>
          {isSkeleton ? skeletonList : contact}

          <WrapperButtons>
            {Boolean(onAddClick) && (
              <PrimaryButton
                inline={true}
                size="small"
                onClick={onAddClick}
                iconRight={<AddIcon size={1} color={Colors.White} />}
              >
                Add Contact
              </PrimaryButton>
            )}
          </WrapperButtons>
        </Paper>
      </Container>
    </ErrorBoundary>
  );
};
